export default {
  title: 'Integritetspolicy för Purspot SoftPOS',
  label: 'Integritetspolicy',
  part1: {
    header: 'Introduktion',
    description: 'Purspot SoftPOS värnar om din integritet och är engagerade i att skydda den. I denna Privacy Policy förklarar vi vilka personuppgifter vi samlar in från användare av Purspot SoftPOS-appen, hur vi använder dessa uppgifter och vilka rättigheter du har när det gäller dina personuppgifter. Genom att använda Purspot SoftPOS-appen godkänner du vår insamling och användning av dina personuppgifter enligt denna policy.'
  },
  part2: {
    header: 'Vilka personuppgifter samlar vi in?',
    description: 'Vi samlar in följande personuppgifter från dig när du registrerar dig och använder Purspot SoftPOS-appen:',
    usp: {
      one: 'Företagsinformation: företagsnamn, organisationsnummer, adress, telefonnummer och e-postadress.',
      two: 'Kontaktpersonens information: namn, e-postadress och telefonnummer.',
      three: 'Betalinformation: bankkontonummer och betalningshistorik.'
    }
  },
  part3: {
    header: 'Hur använder vi dina personuppgifter?',
    description: 'Vi använder dina personuppgifter för följande ändamål:',
    usp: {
      one: 'För att tillhandahålla och förbättra Purspot SoftPOS-appen och dess funktioner.',
      two: 'För att kommunicera med dig om appen, ditt konto och eventuella supportfrågor.',
      three: 'För att behandla och övervaka dina betalningar och transaktioner.',
      four: 'För att uppfylla våra lagliga skyldigheter, som att följa skatte- och bokföringsregler.'
    }
  },
  part4: {
    header: 'Delning av personuppgifter',
    description: 'Vi kan dela dina personuppgifter med tredje parter i följande situationer:',
    usp: {
      one: 'Med betaltjänstleverantörer och banker för att behandla och övervaka dina betalningar och transaktioner.',
      two: 'Med myndigheter som Skatteverket, när det krävs enligt lag.',
      three: 'Med våra underleverantörer och tjänsteleverantörer som hjälper oss att driva och förbättra Purspot SoftPOS-appen.'
    }
  },
  part5: {
    header: 'Lagring av personuppgifter',
    description: 'Vi lagrar dina personuppgifter så länge det är nödvändigt för att tillhandahålla Purspot SoftPOS-appen och uppfylla våra lagliga skyldigheter.'
  },
  part6: {
    header: 'Dina rättigheter',
    description: 'Du har rätt att begära tillgång till, rättelse av eller radering av dina personuppgifter. Du har också rätt att begära begränsning av eller invända mot vår behandling av dina personuppgifter. Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss på support{\'@\'}purspot.com.'
  },
  part7: {
    header: 'Ändringar av denna Privacy Policy',
    description: 'Vi kan komma att uppdatera denna Privacy Policy från tid till annan. Om vi gör väsentliga ändringar kommer vi att meddela dig via e-post eller genom ett meddelande i Purspot SoftPOS-appen.'
  },
  part8: {
    header: 'Kontaktinformation',
    description: 'Om du har några frågor om denna Privacy Policy eller vår behandling av dina personuppgifter, vänligen kontakta oss på support{\'@\'}purspot.com eller 010-7220290.'
  }
}
