export default {
  title: 'Personalliggare',
  code: 'Kod',
  stop: 'Stämpla ut',
  start: 'Stämpla in',
  staff: 'Personal',
  staffNumber: 'Nr',
  staffPersonalNumber: 'Personnummer',
  date: 'Datum',
  timestamp: 'Tidsstämpel',
  dayOfWeek: 'Veckodag',
  registered: 'Registrerad',
  print: 'Skriv ut',
  advancedSearch: 'Avancerad sök',
  search: 'Sök',
  staffAll: 'Alla',
  searchFrom: 'Från',
  searchTo: 'Till',
  fromDate: 'Från datum',
  fromTime: 'Från tid',
  toDate: 'Till datum',
  toTime: 'Till tid',
  to: 'Till',
  detailsSearch: 'Detaljerat resultat',
  openEntry: 'Öppna',
  editEntry: 'Redigera pass',
  addEntry: 'Skapa pass',
  saveEntry: 'Spara',
  duration: 'Varaktighet',
  total: 'Total'
}
