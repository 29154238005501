import { debounce, orderBy, sortBy, groupBy } from 'lodash-es'

const lodash = {
  debounce,
  orderBy,
  sortBy,
  groupBy
}

export { lodash }
