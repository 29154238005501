export default {
  notifications: 'Notifications',
  severity: 'Severity',
  type: 'Type',
  title: 'Title',
  publishDate: 'Publish date',
  endDate: 'End date',
  attachments: 'Attachments',
  readMore: 'Read more'
}
