export default {
  title: 'Staff ledger',
  code: 'Code',
  stop: 'Check out',
  start: 'Check in',
  staff: 'Staff',
  staffNumber: 'No',
  staffPersonalNumber: 'Personal number',
  date: 'Date',
  timestamp: 'Timestamp',
  dayOfWeek: 'Day of week',
  registered: 'Registered',
  print: 'Print',
  advancedSearch: 'Advanced search',
  search: 'Search',
  staffAll: 'All',
  searchFrom: 'From',
  searchTo: 'To',
  fromDate: 'From date',
  fromTime: 'From time',
  toDate: 'To date',
  toTime: 'To time',
  to: 'To',
  detailsSearch: 'Detailed result',
  openEntry: 'Open',
  editEntry: 'Edit entry',
  addEntry: 'Add entry',
  saveEntry: 'Save',
  duration: 'Duration',
  total: 'Total'
}
