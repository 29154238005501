export default {
  notifications: 'Notifications',
  severity: 'Nivå',
  type: 'Typ',
  title: 'Titel',
  publishDate: 'Utgivningsdatum',
  endDate: 'Slutdatum',
  attachments: 'Bilagor',
  readMore: 'Läs mer'
}
