import { storeToRefs } from 'pinia'
import { clientStorage } from './clientStorage'
import { useGlobalStore } from '../stores/global'
import { I18n, createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { watch } from 'vue'
import { createSingletonSetup } from './common/singleton'

interface I18nInstance {
  i18n: I18n<unknown, unknown, unknown, boolean>,
  t(key: string): string,
  t(key: string, named: object): string
}

export const useI18n = createSingletonSetup(() => {
  const storedLocale = clientStorage.getLocale() ?? 'en-GB'
  const globalStore = useGlobalStore()
  const { locale } = storeToRefs(globalStore)

  if (isString(storedLocale)) {
    locale.value = storedLocale
  }

  const i18n = createI18n({
    locale: storedLocale,
    globalInjection: true,
    messages
  })

  watch(
    () => locale.value,
    (v) => {
      clientStorage.setLocale(v)

      // vue-i18n supports both Options API (legacy) and Composition API
      // locale.value is used because we're not using legacy mode
      i18n.global.locale.value = v
    }
  )

  const i18nInstance: I18nInstance = {
    i18n,
    t: i18n.global.t
  }

  return i18nInstance
})

function isString(test: unknown): test is string {
  return typeof test === 'string'
}
