export default {
  noFeatures: 'Inga funktioner tillgängliga',
  shop: 'Allt-i-ett POS',
  paymentTerminal: 'Kassakopplad terminal',
  standAloneTerminal: 'Fristående terminal',
  kioskMode: 'Kioskläge',
  orderList: 'Orderlista',
  consumerOrderList: 'Orderlista, konsument',
  header: 'Försäljningsläge',
  subHeader: 'Vänligen välj försäljningsläge för enheten.',
  rememberChoice: 'Kom ihåg mitt val.'
}
