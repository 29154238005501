export default {
  xreports: 'X-rapporter',
  xreport: 'X-rapport',
  zreports: 'Z-rapporter',
  zreport: 'Z-rapport',
  zdailyreport: 'Z-Dagsrapport',
  periodreports: 'Periodrapporter',
  periodreport: 'Periodrapport',
  cashierreports: 'Kassörrapporter',
  cashierreport: 'Kassörrapport',
  cashRegisters: 'Kassaregister',
  staffreports: 'Timrapporter',
  staffreport: 'Timrapport',
  articlereports: 'Artikelrapporter',
  articlereport: 'Artikelrapport',
  staff: 'Kassör',
  search: 'Sök',
  update: 'Uppdatera',
  from: 'Från',
  to: 'Till',
  all: 'Alla',
  current: 'Nuvarande',
  openReport: 'Öppna rapport',
  print: 'Skriv ut',
  printerUnavailable: 'Skrivare ej tillgänglig',
  sendEmail: 'Skicka e-post',
  createReport: 'Skapa rapport',
  createReportSelected: 'Skapa rapport (valda)',
    createReportMerchant: 'Skapa rapport (alla)',
  reportCreated: 'Rapport skapad',
  reportNotCreated: 'Rapport ej skapad',
  includeIndividualReports: 'Inkludera individuella rapporter',
  accounting: {
    voucherDetails: 'Bokföringsunderlag',
    account: 'Konto',
    description: 'Radtext',
    debit: 'Debet',
    credit: 'Kredit',
    showVoucher: 'Visa bokföringsunderlag',
    downloadPdf: 'Ladda ner som PDF',
    downloadVoucher: 'Ladda ner bokföringsunderlag (SIE)',
    exportFortnox: 'Exportera till Fortnox',
    sentToFortnox: 'Skickat till Fortnox'
  },
  tableHeader: {
    creationdate: 'Skapad Datum',
    reportNumber: 'Rapportnr',
    salesTotal: 'Bruttoförsäljning',
    cashRegister: 'Kassa'
  },
  sendZReport: 'Skicka z-rapport',
  sendZDailyReport: 'Skicka z-dagsrapport',
  sendXReport: 'Skicka x-rapport',
  sendCashierReport: 'Skicka kassörrapport',
  sendStaffReport: 'Skicka timrapport',
  sendArticleReport: 'Skicka artikelrapport',
  sendPeriodReport: 'Skicka periodrapport',
  sendPdf: 'Skicka PDF',
  sendSie: 'Skicka SIE'
}
