export default {
  language: {
    title: 'Steg 1 av 6',
    header: 'Välj språk',
    description: 'Välkommen! Välj det språk du föredrar att använda för din SoftPOS-app.',
    button: 'Nästa',
    country: 'Land',
    sweden: 'Sverige'
  },
  register: {
    title: 'Steg 2 av 6',
    header: 'Registrera dig',
    description: 'Nu kör vi! Skapa ditt konto genom att fylla i formuläret med din information och klicka på skicka.',
    button: 'Registrera',
    footerText: 'Har du redan ett konto',
    footerLink: 'Logga in',
    privacy: 'Integritetspolicy',
    terms: 'Villkor',
    errorPolicyTerms: 'Godkänn integritetspolicy och villkor för att fortsätta',
    form: {
      businessName: 'Företagsnamn',
      orgNumber: 'Org.nummer',
      firstName: 'Förnamn',
      lastName: 'Efternamn',
      mobile: 'Mobilnummer',
      email: 'E-post adress',
      password: 'Lösenord'
    }
  },
  questions: {
    title: 'Steg 3 av 6',
    header: 'Extra information',
    description: 'Vi vill veta lite mer om ditt företag. Besvara några frågor för att hjälpa oss onboarda dig så smidigt som möjligt.',
    button: 'Nästa',
    paymentMethod: 'Önskade betalsätt?',
    payment: {
      card: 'Kort',
      cash: 'Kontant',
      swish: 'Swish'
    },
    form: {
      how: 'Användning?',
      branch: 'Verksamhetsområde?'
    }
  },
  verifyInfo: {
    title: 'Steg 4 av 6',
    header: 'Verifiera din information',
    description: 'Kontrollera att din information är korrekt. Om allt stämmer, klicka på nästa för att fortsätta.',
    button: 'Nästa'
  }
}
