export default {
  main: {
    backOffice: 'Admin',
    connectionState: 'Anslutningsstatus: {state}',
    connection: 'Anslutning',
    home: 'Hem',
    orders: 'Ordrar',
    receipts: 'Kvitton',
    transactions: 'Transaktioner',
    reports: 'Rapporter',
    xreport: 'X-rapport',
    xreports: 'X-rapporter',
    zreports: 'Z-rapporter',
    zdailyreport: 'Z-Dagsrapport',
    periodreports: 'Periodrapporter',
    cashierreport: 'Kassörrapport',
    staffreport: 'Timrapport',
    articlereport: 'Artikelrapport',
    debug: 'Debug',
    about: 'Om',
    quit: 'Avsluta',
    logout: 'Logga ut',
    settings: 'Inställningar',
    products: 'Artiklar',
    switch: 'Byt kassör/plats',
    staffLedger: 'Personalliggare',
    welcomeTo: 'Välkommen till'
  },
  fab: {
    action: 'Utöka verktygsmeny',
    settings: 'Inställningar',
    mainMenu: 'Huvudmeny',
    switch: 'Byt'
  },
  pinned: {
    cashier: 'Kassa',
    tableOrder: 'Bord',
    parkOrder: 'Parkera order',
    receipts: 'Kvitton',
    orders: 'Ordrar',
    xReport: 'X Rapport',
    settings: 'Inställningar',
    staffLedger: 'Personalliggare'
  }
}
