export default {
  editor: 'Anpassa meny knappar',
  uploadSelect: 'Välj fil',
  exit: 'Exit',
  remove: 'Ta bort',
  save: 'Spara',
  order: 'Order',
  columnCount: 'Kolumnantal',
  name: 'Namn',
  delete: 'Radera',
  pleaseConfirm: 'Vänligen bekräfta',
  ok: 'Ok',
  cancel: 'Avbryt',
  areYouSureYouWantToDeleteCurrentCustomView: 'Är du säker på att du vill ta bort den aktuella anpassade menyn?'
}
