import payment from './shopPayment'

export default {
  openCart: 'Open cart',
  cart: 'Cart',
  cancelPurchase: 'Cancel purchase',
  amount: 'Amount',
  total: 'Total',
  customAmount: 'Custom amount',
  openAmount: 'Open amount',
  splitPaymentAmount: 'Partial payment amount',
  partialPayment: 'Partial payment',
  partialAmount: 'Partial amount',
  prePaid: 'Prepaid',
  remainingAmount: 'Remaining',
  notes: 'Notes',
  price: 'Price',
  quantity: 'Quantity',
  refund: 'Refund',
  auth: {
    login: 'Log in',
    location: 'Location',
    staff: 'Staff',
    choose: 'Choose'
  },
  categoryOther: 'Other',
  categoryAll: 'All',
  customize: 'Customize',
  discount: 'Discount',
  coupon: 'Coupon',
  discountPercentage: 'Percentage',
  discountAmount: 'Amount',
  changePettyCash: 'Change petty cash',
  pettyCash: 'Petty cash',
  openDrawer: 'Open drawer',
  delivery: 'Delivery',
  eatHere: 'Eat here',
  takeAway: 'Take away',
  pager: 'Pager',
  emptyCart: 'Empty',
  buttonPayment: 'Payment',
  payment,
  error: {
    paymentMethodUnavailable: 'Payment method not available',
    paymentMethodBusy: 'Payment method is busy'
  },
  confirmation: 'Confirmation',
  consumerName: 'Name',
  consumerPhone: 'Phone',
  consumerAddress: 'Address',
  consumerZip: 'Zip',
  consumerCity: 'City',
  parking: {
    addSpots: 'Add parking spots',
    parkOrder: 'Park order',
    parkCartTitle: 'Park {tabNumber} cart',
    openCart: 'Park {tabNumber} cart',
    splitTabTitle: 'Split park {tabNumber}',
    splitTabName: 'Park {tabNumber}',
    splitTabNoTarget: 'Select target park below'
  },
  lastPayment: {
    link: 'Show last payment',
    title: 'Last payment',
    order: 'Order',
    tryResolve: 'Try resolve payment',
    forceCancel: 'Force cancel',
    notifyUnresolved: 'Unresoved payment!',
    status: {
      created: 'Created',
      paid: 'Paid',
      declined: 'Declined',
      cancelledByMerchant: 'Cancelled - indetermined',
      cancelledByConsumer: 'Cancelled'
    },
    info: {
      paymentMethod: 'Payment method',
      amount: 'Amount',
      status: 'Status',
      created: 'Created'
    }
  }
}
