export default {
  tableOrder: 'Table order',
  tableName: 'Table {tableName}',
  splitTabTitle: 'Split table {tableName}, tab {tabNumber}',
  splitTabNoTarget: 'Select target tab below',
  detailsTitleNoTab: 'Table {tableName} cart',
  detailsTitleTab: 'Table {tableName} - {tabNumber} cart',
  openCart: 'Table {tableName} cart',
  tableInfo: {
    table: 'Table',
    cashier: 'Cashier',
    capacity: 'Capacity',
    orders: 'Orders',
    total: 'Total',
    tabs: 'Tabs',
    notes: 'Notes',
    seatsTaken: 'Seats occupied',
    firstOrderAt: 'First order'
  },
  moveActionText: 'Move tab {tabNumber} on table {tableName}',
  moveTargetTableDetails: 'Table {tableName}',
  moveTargetDescription: 'Move {sourceTabName} on {sourceTableName} to {targetTableName}',
  move: 'Move to table {tableName}',
  moveChangeTable: 'Change table',
  moveSuccess: 'Tab moved',
  viewModeGrid: 'Grid',
  viewModeEdit: 'Edit',
  viewModeInfo: 'Info'
}
