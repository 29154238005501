const clientStorage = {
  setDeviceId(id: string) {
    trySetItem('sp.deviceId', id)
  },
  getDeviceId() {
    return tryGetItem('sp.deviceId')
  },
  setCartLog(log: object) {
    trySetItem('sp.cart-log', log)
  },
  getCartLog() {
    return tryGetItem('sp.cart-log') as [] | null | undefined
  },
  setCartLogId(id: string | null) {
    trySetItem('sp.cart-log-id', id)
  },
  setLocale(locale: string) {
    trySetItem('sp.locale', locale)
  },
  getLocale() {
    const locale = tryGetItem('sp.locale') as string | null | undefined
    if (locale === 'en-US') {
      clientStorage.setLocale('en-GB')
      return 'en-GB'
    }

    return locale
  },
  setOrderListState (state: object) {
    trySetItem('sp.orderList', state)
  },
  getOrderListState () {
    return tryGetItem('sp.orderList')
  },
  setConsumerOrderListState (state: object) {
    trySetItem('sp.consumerOrderList', state)
  },
  getConsumerOrderListState () {
    return tryGetItem('sp.consumerOrderList')
  }
}

let hasStorage: boolean | null = null

function _canStore() {
  try {
    const test = 'test'
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch {
    return false
  }
}

function _storageAvailable() {
  if (hasStorage === null) {
    hasStorage = _canStore()
  }

  return hasStorage
}

function tryGetItem(key: string): number | string | object | null {
  if (_storageAvailable()) {
    const item = localStorage.getItem(key)
    if (item != null) {
      return JSON.parse(item)
    }
    return null
  }

  return null
}

function trySetItem(key: string, value: number | string | object | null) {
  if (_storageAvailable()) {
    const v = value == null ? null : JSON.stringify(value)
    if (v == null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, v)
    }
  }
}

export { clientStorage }
