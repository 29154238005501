export default {
  title: 'Refund',
  details: 'Details',
  orderNumber: 'Order',
  total: 'Total',
  rows: 'Rows',
  rowsToRefund: 'Rows to refund',
  all: 'All',
  buttonRefund: 'Refund',
  buttonBack: 'Return',
  buttonClose: 'Close',
  buttonReset: 'Reset',
  refundCode: 'Refund code',
  cancelRefund: 'Cancel refund',
  refundSuccess: 'Refund successful',
  incomplete: 'Refund not completed',
  verifying: 'Verifying refund',
  error: {
    noPayments: 'Not refundable',
    multiplePayments: 'Refund not supported',
    paymentMethodUnavailable: 'Payment method not available',
    paymentMethodBusy: 'Payment method is busy',
    initFailed: 'Request failed',
    verifyIncomplete: 'Refund incomplete',
    verifyTimeout: 'Refund timed out',
    failed: 'Refund failed',
    invalidType: 'Invalid order type'
  }
}
