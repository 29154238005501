export function createSingletonSetup<T, P = void>(setup: (params?: P) => T):
  (params?: P) => T {
  let instance: T | null = null

  return function useSingleton(params?: P) {
    if (!instance) {
      instance = setup(params)
    }
    return instance
  }
}
