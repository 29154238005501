export default {
  forbidden: 'Åtkomst nekad för användare {user}',
  forbiddenShort: 'Åkomst nekad',
  logout: 'Logga ut',
  username: 'Användarnamn',
  login: {
    title: 'Logga in',
    description: 'Ange din e-postadress och lösenord för att komma åt ditt SoftPOS-konto.',
    username: 'Användarnamn',
    password: 'Lösenord',
    rememberMe: 'Kom ihåg mig',
    forgotPassword: 'Glömt lösenordet',
    button: 'Logga in',
    registerInfo: 'Har du inget konto,',
    register: 'Registrera dig'
  },
  forgotPassword: {
    title: 'Glömt lösenordet',
    link: 'Glömt lösenordet',
    description: 'Glömt ditt lösenord? Ingen fara! Ange ditt användarnamn och vi skickar instruktioner för att återställa det.',
    button: 'Skicka',
    sent: {
      title: 'Skickat!',
      text: 'Om den användarnamnet som du skrev finns registrerat hos oss så har vi nu skickat ett e-post till dig.',
      button: 'Tillbaka'
    }
  },
  resetPassword: {
    title: 'Ange nytt lösenord',
    description: 'Skydda ditt konto genom att skapa ett nytt lösenord. Se till att det är starkt och enkelt för dig att komma ihåg.',
    button: 'Skicka',
    labelOne: 'Lösenord',
    labelTwo: 'Lösenord igen',
    sent: {
      title: 'Klart!',
      text: 'Nu kan du logga in med ditt nya lösenord',
      button: 'Till logga in'
    },
    errorPasswordMatch: 'Lösenord är olika'
  },
  verifyEmail: {
    title: 'Verifiera e-post',
    header: 'Verifiera din e-postadress',
    description: 'Kolla din inkorg för ett verifierings-e-postmeddelande från oss. Klicka på länken för att bekräfta din e-postadress.',
    subTitle: 'Gå till din e-post',
    buttonCheck: 'Kontrollera status',
    footerText: 'Har du inte fått ditt verifieringsmejl,',
    footerLink: 'Skicka igen',
    logout: 'Logga ut',
    sent: {
      title: 'Skickat!',
      text: 'Vi har nu skickat ett nytt e-post till din angivna e-postadress.'
    }
  },
  confirmEmail: {
    title: 'Bekräftar e-post',
    header: 'Bekräftar e-postadress'
  },
  waitForActivation: {
    title: 'Kontot verifieras',
    header: 'Kontot verifieras',
    description: 'Håll i hatten! Vi granskar din information och aktiverar ditt konto så snabbt som möjligt. Vi kontaktar dig om vi behöver något mer.',
    button: 'Väntar på verfiering',
    logout: 'Logga ut'
  }
}
