export default {
  title: 'Privacy Policy for Purspot SoftPOS',
  label: 'Privacy Policy',
  part1: {
    header: 'Introduction',
    description: 'Purspot SoftPOS values your privacy and is committed to protecting it. In this Privacy Policy, we explain what personal data we collect from users of the Purspot SoftPOS app, how we use that data, and what rights you have regarding your personal data. By using the Purspot SoftPOS app, you agree to our collection and use of your personal data in accordance with this policy.'
  },
  part2: {
    header: 'What personal data do we collect?',
    description: 'We collect the following personal data from you when you register and use the Purspot SoftPOS app:',
    usp: {
      one: 'Business information: business name, organization number, address, phone number, and email address.',
      two: 'Contact person information: name, email address, and phone number.',
      three: 'Payment information: bank account number and payment history.'
    }
  },
  part3: {
    header: 'How do we use your personal data?',
    description: 'We use your personal data for the following purposes:',
    usp: {
      one: 'To provide and improve the Purspot SoftPOS app and its features.',
      two: 'To communicate with you about the app, your account, and any support issues.',
      three: 'To process and monitor your payments and transactions.',
      four: 'To comply with our legal obligations, such as tax and accounting rules.'
    }
  },
  part4: {
    header: 'Sharing of personal data',
    description: 'We may share your personal data with third parties in the following situations:',
    usp: {
      one: 'With payment service providers and banks to process and monitor your payments and transactions.',
      two: 'With authorities such as the Swedish Tax Agency, when required by law.',
      three: 'With our subcontractors and service providers who help us operate and improve the Purspot SoftPOS app.'
    }
  },
  part5: {
    header: 'Storage of personal data',
    description: 'We store your personal data for as long as necessary to provide the Purspot SoftPOS app and comply with our legal obligations.'
  },
  part6: {
    header: 'Your rights',
    description: 'You have the right to request access to, correction of, or deletion of your personal data. You also have the right to request restriction of or object to our processing of your personal data. If you wish to exercise any of these rights, please contact us at support{\'@\'}purspot.com.'
  },
  part7: {
    header: 'Changes to this Privacy Policy',
    description: 'We may update this Privacy Policy from time to time. If we make material changes, we will notify you by email or through a message in the Purspot SoftPOS app.'
  },
  part8: {
    header: 'Contact information',
    description: 'If you have any questions about this Privacy Policy or our processing of your personal data, please contact us at support{\'@\'}purspot.com or +46 10 722 0290.'
  }
}
