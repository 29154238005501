import { route } from 'quasar/wrappers'
import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import routes from './routes'
import { useGlobalStore } from 'stores/global'
import { FEATURE, useFeatureStore } from 'stores/feature'
import { notify } from 'src/plugins/notify'
import { useI18n } from 'src/plugins/i18n'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  const store = useGlobalStore()
  const { hasFeature } = useFeatureStore()
  const { t } = useI18n()

  Router.beforeEach((to, from, next) => {
    if (to.meta.authorize) {
      if (store.isActive) {
        if (isString(to.meta.authorize) && isFeature(to.meta.authorize)) {
          if (hasFeature(to.meta.authorize)) {
            next()
          } else {
            notify.error(t('auth.forbiddenShort'))
            next({ name: 'root' })
          }
        } else {
          next()
        }
      } else if (store.isAuthenticated && to.meta.allowInactive) {
        next()
      } else {
        store.setReturnTo(to.path)
        next({ name: 'auth.tryLogin' })
      }
    } else {
      next()
    }
  })

  return Router
})

function isString(test: unknown): test is string {
  return typeof test === 'string'
}

function isFeature(value: string): value is FEATURE {
  return Object.values(FEATURE).includes(value as FEATURE)
}
