export default {
  summaryOrders: 'Orders',
  summaryArticles: 'Articles',
  summaryDuration: 'Duration',
  countValue: '{count}x',
  articles: 'Products',
  sortQuantity: 'Quantity',
  sortAlphabet: 'Name',
  deliverTable: 'Table ready',
  undoTable: 'Undo table',
  deliverOrder: 'Order ready',
  undoOrder: 'Undo order'
}
