export default {
  ticketQueue: 'Ticket Queue',
  history: 'History',
  current: 'Current',
  detailsTitleTab: 'Ticket Queue {id}',
  details: 'Details',
  pagerNumber: 'Pager number (pucks)',
  total: 'Total',
  notes: 'Notes',
  number: 'Number',
  orderNumber: 'Order Number',
  queueNumber: 'Queue Number',
  order: 'Order',
  date: 'Date',
  cashier: 'Cashier',
  location: 'Location',
  categories: 'Categories',
  orderCount: 'Number of orders',
  cancelTicketQueue: 'Cancel Ticket Queue',
  sendTicketQueue: 'Send Ticket Queue',
  noTickets: 'No ticket to show',
  QHistory: 'Q-History',
  ticketQ: 'Ticket-Q'
}
