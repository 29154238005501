export default {
  tableOrder: 'Bord',
  tableName: 'Bord {tableName}',
  splitTabTitle: 'Dela bord {tableName}, nota {tabNumber}',
  splitTabNoTarget: 'Välj målnota nedan',
  detailsTitleNoTab: 'Bord {tableName} varukorg',
  detailsTitleTab: 'Bord {tableName} - {tabNumber} varukorg',
  openCart: 'Bord {tableName} varukorg',
  tableInfo: {
    table: 'Bord',
    cashier: 'Kassör',
    capacity: 'Platser',
    orders: 'Ordrar',
    total: 'Total',
    tabs: 'Notor',
    notes: 'Notering',
    seatsTaken: 'Upptagna platser',
    firstOrderAt: 'Första order'
  },
  moveActionText: 'Flytta nota {tabNumber} på bord {tableName}',
  moveTargetTableDetails: 'Table {tableName}',
  moveTargetDescription: 'Flytta {sourceTabName} på {sourceTableName} till {targetTableName}',
  move: 'Flytta till bord {tableName}',
  moveChangeTable: 'Byt bord',
  moveSuccess: 'Nota flyttad',
  viewModeGrid: 'Rutnät',
  viewModeEdit: 'Redigera',
  viewModeInfo: 'Info'
}
