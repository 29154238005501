import privacy from './privacy'
import terms from './terms'

export default {
  title: 'About',
  info: {
    merchantName: 'Merchant name',
    deviceId: 'Device ID',
    deviceName: 'Name',
    udid: 'UDID',
    appId: 'App ID',
    appName: 'App name',
    appVersion: 'App version',
    posName: 'POS',
    cashRegister: 'Cash register',
    controlUnit: 'Control unit',
    staff: 'Cashier',
    userAgent: 'User agent'
  },
  privacy,
  terms
}
