import privacy from './privacy'
import terms from './terms'

export default {
  title: 'Om',
  info: {
    merchantName: 'Handlare',
    deviceId: 'EnhetsID',
    deviceName: 'Namn',
    udid: 'UDID',
    appId: 'AppID',
    appName: 'Appnamn',
    appVersion: 'Appversion',
    posName: 'System',
    cashRegister: 'Kassa',
    controlUnit: 'Kontrollenhet',
    staff: 'Kassör',
    userAgent: 'User agent'
  },
  privacy,
  terms
}
