export default {
  title: 'Terms and Conditions for Purspot SoftPOS',
  label: 'Terms and Conditions',
  pre: 'Terms and Conditions for Purspot SoftPOS App',
  description: 'These terms and conditions ("Terms") describe the use of the Purspot SoftPOS App ("the App") provided by Purspot AB ("Purspot", "we", "us", "our"). By using the App, you agree to these Terms and commit to following them.',
  part1: {
    header: 'Use of the App',
    description: 'The App is intended for merchants who want to use their mobile phone as a cash register and payment terminal to receive card payments without extra hardware. The App is only allowed to be used for business purposes, and not for personal use.'
  },
  part2: {
    header: 'Registration and Account Information',
    description: 'To use the App, you must register and create an account. You are responsible for ensuring that all the information you provide is accurate and up to date. You are also responsible for the security of your account and password.'
  },
  part3: {
    header: 'Costs and Payment',
    description: 'Use of the App is subject to payment of a monthly fee and a transaction fee per payment transaction. Fees may change from time to time with advance notice. You are responsible for paying all fees on time. Purspot may take legal action to collect unpaid fees.'
  },
  part4: {
    header: 'Limitation of Liability',
    description: 'The App is provided "as is" without any warranties of any kind, express or implied. Purspot is not responsible for any losses or damages caused by the use of the App, including but not limited to indirect, consequential, or incidental damages, lost revenues, or business interruptions.'
  },
  part5: {
    header: 'Changes to the Terms',
    description: 'Purspot may update these Terms from time to time. If changes are made, we will notify you through the App or via email. It is your responsibility to regularly review these Terms to stay informed of any changes.'
  },
  part6: {
    header: 'Termination',
    description: 'Purspot reserves the right to terminate your account and use of the App at any time if you violate these Terms or use the App in a way that harms our business or reputation.'
  },
  part7: {
    header: 'Applicable Law and Dispute Resolution',
    description: 'These Terms are governed by Swedish law and any disputes shall be resolved in the Stockholm District Court.'
  },
  part8: {
    header: 'Contact',
    description: 'If you have any questions or concerns about these Terms or the use of the App, you can contact us at support{\'@\'}purspot.com or +46 10 722 02 90.'
  }
}
