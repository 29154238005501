export default {
  settings: 'Settings',
  refresh: 'Refresh app',
  language: {
    title: 'Language',
    description: 'Please select a language',
    language: 'Language'
  },
  articleList: {
    title: 'Article list',
    activeArticleList: 'Active article list',
    schedule: 'Week schedule'
  },
  appInfo: {
    update: {
      title: 'Update app',
      description: 'There is a new version of SoftPOS available',
      buttonOpenStore: 'Open store',
      buttonExit: 'Quit'
    }
  },
  printer: {
    printer: 'Printer',
    printTest: 'Print test',
    printerUnavailable: 'Printer not available',
    noPrinters: 'No printers',
    setDefaultPrinter: 'Set as default printer',
    clearPrintQueue: 'Clear Queue'
  },
  paymentDevice: {
    paymentDevice: 'Payment device',
    paymentDevices: 'Payment devices',
    settle: 'Settle'
  },
  paymentDeviceLogType: {
    instruction: 'Instruction',
    consumer: 'Consumer',
    merchant: 'Merchant',
    lastTransaction: 'LastTransaction',
    settle: 'Settle',
    print: 'Print',
    message: 'Message',
    error: 'Error',
    receipt: 'Receipt'
  }
}
