import ordersCurrent from './ordersCurrent'
import orderDetails from './ordersDetails'

export default {
  statusBar: {
    newOrder: 'New order',
    newOrders: 'New orders'
  },
  current: ordersCurrent,
  details: orderDetails,
  status: {
    started: 'Started',
    cancelled: 'Cancelled',
    success: 'Success',
    created: 'Created',
    waiting: 'Waiting for tip',
    partialPaid: 'Partially paid'
  }
}
