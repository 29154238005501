export default {
  summaryOrders: 'Ordrar',
  summaryArticles: 'Artiklar',
  summaryDuration: 'Varaktighet',
  countValue: '{count}x',
  articles: 'Produkter',
  sortQuantity: 'Antal',
  sortAlphabet: 'Namn',
  deliverTable: 'Bord redo',
  undoTable: 'Ångra bord',
  deliverOrder: 'Order redo',
  undoOrder: 'Ångra order'
}
