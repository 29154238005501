export default {
  editor: 'Custom views editor',
  uploadSelect: 'Select file',
  exit: 'Exit',
  remove: 'Remove',
  save: 'Save',
  order: 'Order',
  columnCount: 'Column count',
  name: 'Name',
  delete: 'Delete',
  pleaseConfirm: 'Please confirm',
  ok: 'Ok',
  cancel: 'Cancel',
  areYouSureYouWantToDeleteCurrentCustomView:
    'Are you sure you want to delete current custom view'
}
