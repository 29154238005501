export default {
  openCart: 'Varukorg',
  customize: 'Anpassa',
  quantity: 'Kvantitet',
  goToStart: 'Gå till Start',
  continueShopping: 'Fortsätt handla',
  eatHere: 'Äta här',
  takeaway: 'Ta med',
  total: 'Total',
  orderNumber: 'Ordernummer',
  digitalReceipt: 'Digitalt Kvitto.',
  getNotifiedWhenYourFoodIsReady: 'Få ett meddelande när din mat är klar.',
  orderWillBeResetIn: 'Beställningen återställs om'
}
