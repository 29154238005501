export default {
  title: 'Villkor för Purspot SoftPOS',
  label: 'Villkor',
  pre: 'Terms and Conditions för Purspot SoftPOS Appen',
  description: 'Dessa villkor ("Villkor") beskriver användningen av Purspot SoftPOS Appen ("Appen") som är tillhandahållen av Purspot AB ("Purspot", "vi", "oss", "vår"). Genom att använda Appen godkänner du dessa Villkor och förbinder dig att följa dem.',
  part1: {
    header: 'Användning av Appen',
    description: 'Appen är avsedd för handlare som vill använda sin mobiltelefon som kassasystem och betalterminal för att ta emot kortbetalningar utan extra hårdvara. Appen är endast tillåten att användas för affärsändamål, och inte för personligt bruk.'
  },
  part2: {
    header: 'Registrering och kontoinformation',
    description: 'För att använda Appen måste du registrera dig och skapa ett konto. Du är ansvarig för att all information du lämnar är korrekt och uppdaterad. Du ansvarar också för säkerheten för ditt konto och lösenord.'
  },
  part3: {
    header: 'Kostnader och betalning',
    description: 'Användning av Appen är föremål för betalning av en månadsavgift och en transaktionsavgift per betalningstransaktion. Avgifterna kan ändras från tid till annan med förvarning. Du ansvarar för att betala alla avgifter i tid. Purspot kan vidta rättsliga åtgärder för att driva in obetalda avgifter.'
  },
  part4: {
    header: 'Ansvarsbegränsning',
    description: 'Appen tillhandahålls "som den är" utan några garantier av något slag, uttryckliga eller underförstådda. Purspot ansvarar inte för förluster eller skador som orsakats av användningen av Appen, inklusive men inte begränsat till indirekta, följd-, eller påföljande skador, förlorade intäkter eller avbrott i verksamheten.'
  },
  part5: {
    header: 'Ändringar i Villkoren',
    description: 'Purspot kan uppdatera dessa Villkor från tid till annan. Om ändringar görs kommer vi att meddela dig genom Appen eller via e-post. Det är ditt ansvar att regelbundet granska dessa Villkor för att hålla dig informerad om eventuella ändringar.'
  },
  part6: {
    header: 'Uppsägning',
    description: 'Purspot förbehåller sig rätten att avsluta ditt konto och användning av Appen när som helst om du bryter mot dessa Villkor eller om du använder Appen på ett sätt som skadar vår verksamhet eller rykte.'
  },
  part7: {
    header: 'Tillämplig lag och tvistlösning',
    description: 'Dessa Villkor regleras av svensk lag och alla tvister ska lösas vid Stockholms tingsrätt.'
  },
  part8: {
    header: 'Kontakt',
    description: 'Om du har frågor eller funderingar om dessa Villkor eller användningen av Appen kan du kontakta oss via support{\'@\'}purspot.com eller 010-7220290'
  }
}
