export default {
  receipts: 'Kvitton',
  receipt: 'Kvitto',
  search: 'Sök',
  from: 'Från',
  to: 'Till',
  openOrder: 'Öppna order',
  titleDetails: 'Ordrar',
  items: 'Rader',
  total: 'Totalt',
  excludingVat: 'Exklusive moms',
  vat: 'Moms',
  details: 'Detaljer',
  status: 'Status',
  created: 'Skapad',
  updated: 'Uppdaterad',
  orderStatus: {
    0: 'Startad',
    1: 'Avbruten',
    2: 'Slutförd',
    6: 'Skapad',
    7: 'Väntar på dricks'
  },
  refund: 'Återbetala',
  receiptCopy: 'Kvittokopia',
  receiptCopyPrinted: 'Kvittokopia redan utskriven/skickad',
  printTicket: 'Skriv ut bong',
  printerUnavailable: 'Skrivare ej tillgänglig',
  reverse: 'Reversera'
}
