export default {
  ticketQueue: 'Bongkö',
  history: 'Historik',
  current: 'Pågående',
  detailsTitleTab: 'Bongkö {id}',
  details: 'Detaljer',
  pagerNumber: 'Puck nummer (pucks)',
  total: 'Summa',
  notes: 'Anteckningar',
  number: 'Nummer',
  orderNumber: 'Order Nummer',
  queueNumber: 'Könumber',
  order: 'Order',
  date: 'Datum',
  cashier: 'Kassör',
  location: 'Plats',
  categories: 'Kategorier',
  orderCount: 'Antal Ordrar',
  cancelTicketQueue: 'Avbryt Bongkö',
  sendTicketQueue: 'Skicka Bongkö',
  noTickets: 'Det finns ingen bong att visa',
  QHistory: 'Q-Historik',
  ticketQ: 'Bong-Q'
}
