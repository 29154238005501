export default {
  noFeatures: 'No features available',
  shop: 'All in one POS',
  paymentTerminal: 'Connected terminal',
  standAloneTerminal: 'Stand-alone terminal',
  kioskMode: 'Kiosk mode',
  orderList: 'Order list',
  consumerOrderList: 'Consumer order list',
  header: 'Sales mode',
  subHeader: 'Please select sales mode for this unit.',
  rememberChoice: 'Remember my choice.'
}
