import ordersCurrent from './ordersCurrent'
import orderDetails from './ordersDetails'

export default {
  statusBar: {
    newOrder: 'Ny order',
    newOrders: 'Nya ordrar'
  },
  current: ordersCurrent,
  details: orderDetails,
  status: {
    started: 'Startad',
    cancelled: 'Avbruten',
    success: 'Slutförd',
    created: 'Skapad',
    waiting: 'Väntar på dricks',
    partialPaid: 'Delbetald'
  }
}
