<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'
import { lodash } from './plugins/lodash'

export default defineComponent({
  name: 'App',
  mounted() {
    this.initWatchViewportHeight()
    this.setupStyle()
  },
  unmounted() {
    this.removeWatchViewportHeight()
  },
  methods: {
    setupStyle() {
      // disbleTouchPageScroll causes issue with scrolling - i.e. when keyboard is visible on login screen
      // this.disbleTouchPageScroll()
      this.disableSwipeNavigation()
    },
    disbleTouchPageScroll() {
      document.body.classList.add('pos-body--prevent-scroll')
    },
    disableSwipeNavigation() {
      document.body.classList.add('pos-body--prevent-swipe-nav')
    },
    initWatchViewportHeight() {
      // 1 vh varies on phones depending on if address bar etc is visible or not
      // calculate 1 vh and set as a css variable to use in stylesheets
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      window.addEventListener('resize', this.setViewportHeight)
    },
    removeWatchViewportHeight() {
      window.removeEventListener('resize', this.setViewportHeight)
    },
    setViewportHeight: lodash.debounce(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 300)
  }
})
</script>
