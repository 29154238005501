export default {
  receipts: 'Receipts',
  receipt: 'Receipt',
  search: 'Search',
  from: 'From',
  to: 'To',
  openOrder: 'Open order',
  titleDetails: 'Orders',
  items: 'Items',
  total: 'Total',
  excludingVat: 'Excluding VAT',
  vat: 'VAT',
  details: 'Details',
  status: 'Status',
  created: 'Created',
  updated: 'Updated',
  orderStatus: {
    0: 'Started',
    1: 'Cancelled',
    2: 'Success',
    6: 'Created',
    7: 'Waiting for tip'
  },
  refund: 'Refund',
  receiptCopy: 'Receipt copy',
  receiptCopyPrinted: 'Receipt copy already printed/sent',
  printTicket: 'Print ticket',
  printerUnavailable: 'Printer not available',
  reverse: 'Reverse'
}
