export default {
  main: {
    backOffice: 'Back office',
    connectionState: 'Connection state: {state}',
    connection: 'Connection',
    home: 'Home',
    orders: 'Orders',
    receipts: 'Receipts',
    transactions: 'Transactions',
    reports: 'Reports',
    xreport: 'X Report',
    xreports: 'X Reports',
    zreports: 'Z Reports',
    zdailyreport: 'Z Daily Report',
    periodreports: 'Period Reports',
    cashierreport: 'Cashier Report',
    staffreport: 'Staff Report',
    articlereport: 'Article Report',
    debug: 'Debug',
    about: 'About',
    quit: 'Quit',
    logout: 'Logout',
    settings: 'Settings',
    products: 'Products',
    switch: 'Switch cashier/location',
    staffLedger: 'Staff ledger',
    welcomeTo: 'Welcome to'
  },
  fab: {
    action: 'Expand action menu',
    settings: 'Settings',
    mainMenu: 'Main menu',
    switch: 'Switch'
  },
  pinned: {
    cashier: 'Cashier',
    tableOrder: 'Table Order',
    parkOrder: 'Park Order',
    receipts: 'Receipts',
    orders: 'Orders',
    xReport: 'X Report',
    settings: 'Settings',
    staffLedger: 'Staff ledger'
  }
}
