export default {
  language: {
    title: 'Step 1 of 6',
    header: 'Language',
    description: 'Welcome! Select the language you prefer to use for your SoftPOS app.',
    button: 'Next',
    country: 'Country',
    sweden: 'Sweden'
  },
  register: {
    title: 'Step 2 of 6',
    header: 'Register',
    description: 'Let\'s get started with creating your account. Fill out the form with your information and hit next.',
    button: 'Register',
    footerText: 'Already have an account,',
    footerLink: 'Login',
    privacy: 'Privacy Policy',
    terms: 'Terms and Conditions',
    errorPolicyTerms: 'Consent to privacy policy and terms and conditions to continue',
    form: {
      businessName: 'Business name',
      orgNumber: 'Org.number',
      firstName: 'First name',
      lastName: 'Last name',
      mobile: 'Mobile',
      email: 'Email',
      password: 'Password'
    }
  },
  questions: {
    title: 'Step 3 of 6',
    header: 'Some questions',
    description: 'We want to know a little bit more about your business. Answer a few questions to help us onboard you smoothly.',
    button: 'Next',
    paymentMethod: 'Preferred payment methods?',
    payment: {
      card: 'Card',
      cash: 'Cash',
      swish: 'Swish'
    },
    form: {
      how: 'Usage?',
      branch: 'Type of business?'
    }
  },
  verifyInfo: {
    title: 'Step 4 of 6',
    header: 'Verify your information',
    description: 'Please confirm that your information is correct. If everything is accurate, click on "next" to proceed.',
    button: 'Next'
  }
}
