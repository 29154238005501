export default {
  forbidden: 'Access forbidden for user {user}',
  forbiddenShort: 'Access denied',
  logout: 'Log out',
  username: 'Username',
  login: {
    title: 'Login',
    description: 'Enter your email and password to access your SoftPOS account.',
    username: 'Username',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    button: 'Login',
    registerInfo: 'Don\'t have an account,',
    register: 'Register'
  },
  forgotPassword: {
    title: 'Forgot password',
    link: 'Forgot password',
    description: ' Forgot your password? No worries! Enter your username and we will send you instructions to reset it.',
    button: 'Send',
    sent: {
      title: 'Sent!',
      text: 'We have sent an email with instructions to reset your password.',
      button: 'Back'
    }
  },
  resetPassword: {
    title: 'Set new password',
    description: 'Protect your account by creating a new password. Make sure it is strong and easy for you to remember.',
    button: 'Send',
    labelOne: 'Password',
    labelTwo: 'Password again',
    sent: {
      title: 'Done!',
      text: 'Now you can login with your new password.',
      button: 'To login'
    },
    errorPasswordMatch: 'Passwords doesn\'t match'
  },
  verifyEmail: {
    title: 'Verify email',
    header: 'Verify your email',
    description: 'Check your inbox for a verification email from us. Click the link to confirm your email address.',
    subTitle: 'Go to your inbox',
    buttonCheck: 'Check status',
    footerText: 'Haven\'t received verification email,',
    footerLink: 'Resend',
    logout: 'Logout',
    sent: {
      title: 'Sent!',
      text: 'We have sent a new email.'
    }
  },
  confirmEmail: {
    title: 'Confirming email',
    header: 'Confirming email address'
  },
  waitForActivation: {
    title: 'Activating account',
    header: 'Activating account',
    description: 'Hang tight! We are reviewing your information and activating your account as quickly as possible. We will contact you if needed.',
    button: 'Waiting for activation',
    logout: 'Logout'
  }
}
