export default {
  payWithMobile: 'Betala med mobilen',
  payWithSwish: 'Betala med Swish',
  scanWithYourMobileCamera: 'Skanna med din mobilkamera',
  scanWithSwishApp: 'Skanna med Swish app',
  thanksForYourPayment: 'Tack för din betalning!',
  digitalReceipt: 'Digitalt kvitto',
  forPrintedReceiptTellTheCashier: 'För utskrivet kvitto, säg till kassören',
  digitalReceiptIsInYourMobilePhone: 'Digitalt kvitto finns i din mobiltelefon',
  ThanksForYourPatience: 'Tack för ditt tålamod',
  processing: 'Bearbetar...',
  payAtTheCardTerminal: 'Betala i kortterminalen...',
  waitingForSignalFromTheCardTerminal: 'Väntar återkoppling från kortterminalen'
}
