export default {
  xreports: 'X Reports',
  xreport: 'X Report',
  zreports: 'Z Reports',
  zreport: 'Z Report',
  zdailyreport: 'Z Daily Report',
  periodreports: 'Period Reports',
  periodreport: 'Period Report',
  cashierreports: 'Cashier Reports',
  cashierreport: 'Cashier Report',
  cashRegisters: 'Cash Registers',
  staffreports: 'Staff Reports',
  staffreport: 'Staff Report',
  articlereports: 'Article Reports',
  articlereport: 'Article Report',
  staff: 'Staff',
  search: 'Search',
  update: 'Update',
  from: 'From',
  to: 'To',
  all: 'All',
  current: 'Current',
  openReport: 'Open report',
  print: 'Print',
  printerUnavailable: 'Printer not available',
  sendEmail: 'Send email',
  createReport: 'Create report',
  createReportSelected: 'Create report (selected)',
  createReportMerchant: 'Create report (all)',
  reportCreated: 'Report created',
  reportNotCreated: 'Report not created',
  includeIndividualReports: 'Include individual reports',
  accounting: {
    voucherDetails: 'Voucher details',
    account: 'Account',
    description: 'Description',
    debit: 'Debit',
    credit: 'Credit',
    showVoucher: 'Show voucher',
    downloadPdf: 'Download as PDF',
    downloadVoucher: 'Download voucher (SIE)',
    exportFortnox: 'Export to Fortnox',
    sentToFortnox: 'Sent to Fortnox'
  },
  tableHeader: {
    creationdate: 'Created Date',
    reportNumber: 'Report Nr',
    salesTotal: 'Total Sales',
    cashRegister: 'Cash Register'
  },
  sendZReport: 'Send z report',
  sendZDailyReport: 'Send z daily report',
  sendXReport: 'Send x report',
  sendCashierReport: 'Send cashier report',
  sendStaffReport: 'Send staff report',
  sendArticleReport: 'Send article report',
  sendPeriodReport: 'Send period report',
  sendPdf: 'Send PDF',
  sendSie: 'Send SIE'
}
