import common from './common'
import auth from './auth'
import menu from './menu'
import main from './main'
import about from './about'
import terminal from './terminal'
import shop from './shop'
import print from './print'
import settings from './settings'
import receipts from './receipts'
import refund from './refund'
import reports from './reports'
import orders from './orders'
import register from './register'
import consumerDisplay from './consumerDisplay'
import consumerOrderList from './consumerOrderList'
import kiosk from './kiosk'
import transactions from './transactions'
import ticketQueue from './ticketQueue'
import table from './table'
import tab from './tab'
import staffLedger from './staffLedger'
import notifications from './notifications'
import customViews from './customViews'

export default {
  common,
  auth,
  menu,
  main,
  about,
  terminal,
  shop,
  print,
  settings,
  receipts,
  refund,
  reports,
  orders,
  register,
  consumerDisplay,
  consumerOrderList,
  kiosk,
  transactions,
  ticketQueue,
  table,
  tab,
  staffLedger,
  notifications,
  customViews
}
