import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'

export enum FEATURE {
  COMPLETE_SALE = 'CompleteSale',
  CART_LOG = 'CartLog',
  PAYMENT_TERMINAL = 'PaymentTerminal',
  EXTERNAL_PAYMENT_TERMINAL = 'ExternalPaymentTerminal',
  EXIT = 'ExitApp',
  DEBUG = 'Debug',
  PRINTER = 'Printer',
  OPEN_AMOUNT = 'OpenAmount',
  OPEN_DISCOUNT = 'OpenDiscount',
  MERCHANT_COUPON = 'MerchantCoupon',
  HAS_CASH = 'HasCash',
  HAS_CASH_DRAWER = 'HasCashDrawer',
  SHOP = 'SoftPosShop',
  ONLINE_ORDER = 'OnlineOrder',
  CONSUMER_DISPLAY = 'ConsumerDisplay',
  CONSUMER_ORDER_LIST = 'ConsumerOrderList',
  STANDALONE_TERMINAL = 'StandaloneTerminal',
  KIOSK = 'Kiosk',
  KIOSK_MODE = 'KioskMode',
  KDS = 'Kds',
  FORTNOX = 'Fortnox',
  PARKING = 'Parking',
  PINNED_MENU = 'PinnedMenu',
  TABLE_ORDER = 'TableOrder',
  TABLE_ORDER_MAP = 'TableOrderMap',
  TABLE_ORDER_MAP_EDIT = 'TableOrderMapEdit',
  STAFF_LEDGER = 'StaffLedger',
  CUSTOM_VIEWS = 'CustomViews',
  CUSTOM_VIEWS_EDIT = 'CustomViewsEdit',
  OPEN_REFUND = 'OpenRefund',
  SPLIT_PAYMENT = 'SplitPayment',
  HOTJAR = 'Hotjar',
  TICKET_QUEUE = 'TicketQueue',
  TICKET_QUEUE_HISTORY = 'TicketQueueHistory',
  PINNED_FUNCTIONS = 'PinnedFunctions',
  HAS_PAGER = 'HasPager',
  LAST_TERMINAL_PAYMENT = 'LastTerminalPayment'
}

type FeatureFlags = Record<keyof typeof FEATURE, Ref<boolean>>

export const MODULE = Object.freeze({
  PAYMENT_TERMINAL: 'PAYMENT_TERMINAL',
  SHOP: 'SHOP',
  ONLINE_ORDER: 'ONLINE_ORDER',
  STANDALONE_TERMINAL: 'STANDALONE_TERMINAL',
  KIOSK_MODE: 'KIOSK_MODE',
  CONSUMER_ORDER_LIST: 'CONSUMER_ORDER_LIST'
})

export const useFeatureStore = defineStore('feature', () => {
  const features: FeatureFlags = initializeFlags(FEATURE)
  const featureValues = initializeFlagValues(FEATURE)

  function setFeatures (userFeatures: string[]) {
    setFlags(FEATURE, features, userFeatures)
    setFlagValues(featureValues, userFeatures)
  }

  function hasFeature(feature: FEATURE) {
    return featureValues[feature] != null ? featureValues[feature].value : false
  }

  return {
    features,
    setFeatures,
    hasFeature
  }
})

function initializeFlags<T>(enumType: T): Record<keyof T, Ref<boolean>> {
  const flags: Partial<Record<keyof T, Ref<boolean>>> = {}

  for (const key in enumType) {
    flags[key] = ref(false)
  }

  return flags as Record<keyof T, Ref<boolean>>
}

function initializeFlagValues<T>(enumType: T): Record<string, Ref<boolean>> {
  const flags: Partial<Record<string, Ref<boolean>>> = {}

  for (const key in enumType) {
    const val = enumType[key] as string
    flags[val] = ref(false)
  }

  return flags as Record<string, Ref<boolean>>
}

function setFlags<T>(enumType: T, existingFeatures: Record<keyof T, Ref<boolean>>, myFeatures: string[]) {
  for (const key in enumType) {
    if (myFeatures != null && typeof key === 'string') {
      existingFeatures[key].value = myFeatures.includes(enumType[key] as unknown as string)
    }
  }
}

function setFlagValues(existingFeatures: Record<string, Ref<boolean>>, myFeatures: string[]) {
  for (const key in existingFeatures) {
    if (myFeatures != null && typeof key === 'string') {
      existingFeatures[key].value = myFeatures.includes(key)
    }
  }
}
