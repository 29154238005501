export default {
  openCart: 'Cart',
  customize: 'Customize',
  quantity: 'Quantity',
  goToStart: 'Go To Start',
  continueShopping: 'Continue shopping',
  eatHere: 'Eat here',
  takeaway: 'Takeaway',
  total: 'Total',
  orderNumber: 'Order number',
  digitalReceipt: 'Digital receipt.',
  getNotifiedWhenYourFoodIsReady: 'Get notified when your food is ready.',
  orderWillBeResetIn: 'Order will be reset in',
}
